import { formatInTimeZone } from 'date-fns-tz/esm'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict/index'
import { Model } from '@vuex-orm/core'

export enum EntityType {
  products = 'products',
  categories = 'categories',
  payloads = 'payloads',
  forms = 'forms',
  experiments = 'experiments',
  productVersions = 'productVersions',
  users = 'users',
  charges = 'charges',
  documents = 'documents',
  companies = 'companies',
  sessions = 'sessions',
  orders = 'orders',
  refunds = 'refunds',
  subscriptions = 'subscriptions',
  cards = 'cards',
  plans = 'plans',
  adminUsers = 'adminUsers',
  currentUser = 'currentUser',
  stripeAlerts = 'stripeAlerts',
  chargebacks = 'chargebacks',
  stateInfo = 'stateInfo',
  stats = 'stats',
  daystats = 'daystats',
  notes = 'notes',
  files = 'files',
  recurlyerrors = 'recurlyerrors',
  foreignEntities = 'foreignEntities',
  complianceguard = 'complianceguard',
  complianceitem = 'complianceitem',
  complianceAmendment = 'complianceAmendment',
  complianceorder = 'complianceorder',
  lander = 'lander',
  task = 'task',
  notification = 'notification',
  labels = 'labels',
  teams = 'teams',
  taskRecipe = 'taskRecipe',
  companyMail = 'companyMail',
}

export default class BaseModel extends Model {
  static entity: EntityType

  get created_at_distance() {
    if (this.created_at_obj)
      return formatDistanceToNowStrict(this.created_at_obj, {
        addSuffix: true,
      })
    else return ''
  }

  get created_at_Mdy() {
    if (this.created_at_obj)
      return formatInTimeZone(
        this.created_at_obj,
        'America/Los_Angeles',
        'MM/dd/yyyy'
      )
    else return ''
  }

  get created_at_Mdyhm() {
    if (this.created_at_obj)
      return formatInTimeZone(
        this.created_at_obj,
        'America/Los_Angeles',
        'MM/dd/yyyy HH:mm'
      )
    else return ''
  }

  get created_at_obj() {
    if (this.created_at) return new Date(this.created_at)
  }

  get updated_at_distance() {
    return formatDistanceToNowStrict(this.updated_at_obj, {
      addSuffix: true,
    })
  }

  get updated_at_obj() {
    if (this.updated_at) return new Date(this.updated_at)
  }

  get purchased_at_Mdyhm() {
    if (this.purchased_at_obj)
      return formatInTimeZone(
        this.purchased_at_obj,
        'America/Los_Angeles',
        'MM/dd/yyyy HH:mm'
      )
    else return ''
  }

  get purchased_at_obj() {
    if (this.purchased_at) return new Date(this.purchased_at)
    else return null
  }

  // created_at?: Date
  // updated_at?: Date
  // purchased_at?: Date | string
}
