import BaseModel, { EntityType } from './BaseModel'
import User from './User'
import Company from './Company'

import { Order as OrderInterface } from '~/interfaces/api/Orders.interface'

export default class Order extends BaseModel implements OrderInterface {
  static entity = EntityType.orders

  static state() {
    return {
      total: null,
    }
  }

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      state: this.string(''),
      status: this.string(''),
      order_type: this.string(''),
      entity_type: this.string(''),
      purchased_at: this.attr(''),
      canceled_at: this.attr(''),
      processing_completed_at: this.attr(''),
      purchased_ip: this.string(''),
      company_id: this.number(0),
      company: this.belongsTo(Company, 'company_id'),
      step: this.number(0),
      total_cost: this.number(0),
      total_revenue: this.number(0),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      line_items: this.attr(''),
      data: this.attr(''),
      attachments: this.attr(''),
      labels_assignments: this.attr(''),
    }
  }

  // id!: number
  // name!: string
  // state!: string
  // status!: string
  // order_type!: string
  // entity_type!: string
  // purchased_at: Date
  // purchased_ip: string
  // company_id: number
  // step: number
  // total_cost: number
  // total_revenue: number
  // user_id: number
  // created_at: Date
  // updated_at: Date
  // line_items: Array<{}>
  // data: object

  get total_cost_formatted() {
    return '$' + this.total_cost.toFixed(2)
  }

  get total_revenue_formatted() {
    return '$' + this.total_revenue.toFixed(2)
  }
}
